import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ImageShow from './ImageShow';

const ImageMain = (props) => {
    return (
        <div>
            <Router>
                <div>
                    <Route path="/image/:id" component={ImageShow} />
                </div>
            </Router>
        </div>
    );
}

export default ImageMain;
