import './ImageShow.css';
import React from 'react';

const ImageShow = (props) => {

    const edition = "2020_christmas";

    const findImage = (id) => {
        switch (id) {
            case '12345-11-98765': return `${edition + '/1-Astrid.jpg'}`;
            case '12345-33-98765': return `${edition + '/2-soverom.jpg'}`;
            case '12345-44-98765': return `${edition + '/3-kjøkken.jpg'}`;
            case '12345-55-98765': return `${edition + '/4-spisebord.jpg'}`;
            case '12345-66-98765': return `${edition + '/5-balkong.jpg'}`;
            default: return '';
        }
    }

    return (
        <div style={{textAlign: 'center'}}>
            <img src={`/img/${findImage(props.match.params.id)}`} alt="leit sjøl" />
        </div>
    );
}

export default ImageShow;
